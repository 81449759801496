a.RecipeCardLink {
	text-align: center;
	border-style: solid;
	border-width: 2px;
	margin: 2px;
	width: 32%;
}

a.RecipeCardLink:link {
	text-decoration: none;
	color: rgb(209, 209, 209);
}

a.RecipeCardLink:visited {
	color: rgb(209, 209, 209);
}

a.RecipeCardLink:hover {
	color: #ffcc66;
}

/* .RecipeCard > img {
	width: 100%;
	height: auto;
} */
